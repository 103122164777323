import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { TypografPipe } from '@pirexpo/shared/libs';
import { Partner } from '@swagger/models';
import { NgxCutModule } from 'ngx-cut';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, TypografPipe, NgxCutModule],
  selector: 'pirexpo-card-partner',
  standalone: true,
  styleUrls: ['./card-partner.component.scss'],
  templateUrl: './card-partner.component.html',
})
export class CardPartnerComponent {
  @Input({ required: true }) partner!: Partner;
  @Input({ transform: booleanAttribute }) partnerForPopup?: boolean;
  // eslint-disable-next-line perfectionist/sort-classes
  @Output() openPopup = new EventEmitter<Partner>();
}
