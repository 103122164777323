<div
  class="card-partner"
  [class.card-partner-popup]="partnerForPopup"
  (click)="openPopup.emit(partner)"
>
  <div class="card-partner__img-container">
    @if (partner.logo) {
      <img
        class="card-partner__img"
        fill
        [ngSrc]="partner.logo.image!"
        [alt]="partner.name"
      />
    }
  </div>
  <div class="card-partner__status-container">
    <p class="card-partner__status" ngxCut [size]="2">
      {{ partner.status | typograf }}
    </p>
  </div>
</div>
